<template>
  <div class="white-section">
    <h2>A little bit about me...</h2>
    <div class="divider grey"></div>
    <div class="tri-column">
      <h3>01. EDUCATION</h3>
      <p class="text-block">
        BS in Interior Design<br />Turing School of Software & Design<br />
        Self Study
      </p>
    </div>
    <div class="tri-column">
      <h3>02. work experience</h3>
      <p class="text-block">
        Lighting Designer >> <br />Lighting Sales Rep >><br />Project Manager
        //Actual Manager
      </p>
    </div>
    <div class="tri-column">
      <h3>03. personal experience</h3>
      <p class="text-block">
        I love self dev books, learning, and taking on new challenges.
      </p>
    </div>
    <div class="wrapper button-1">
      <router-link to="/about" style="text-decoration: none"
        >the deets...</router-link
      >
    </div>
  </div>
</template>

<script>
export default {};
</script>
